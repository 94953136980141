.loginModal-box {
  width: 450px;
  height: 580px;
  font-size: 12px;
  &.modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    .modal-content {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    .modal-body {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
  &-content {
    width: 100%;
    height: 100%;
    position: relative;
    &-closeBtn {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 10;
      cursor: pointer;
      img {
        display: block;
        width: 100%;
      }
    }
  }
}
