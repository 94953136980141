.main-header {
  @bg_color: #061f33;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  .header-pc {
    display: none;
  }
  .suggestions {
    background: @bg_color;
    color: #fff;
    .suggestionsResultList-item {
      &:hover,
      &:focus,
      &:active {
        background: #1d374c;
      }
      .itemCon span {
        color: #fff;
        font-weight: 300;
      }
    }
  }
  .header-m {
    @h: 0.8rem;
    display: block;
    background: @bg_color;
    color: #fff;
    height: @h;
    line-height: @h;
    @padding: 0.3rem;
    &.showMenu {
      .nav-title {
        background: rgba(6, 31, 51, 0.9);
        z-index: 111;
        position: fixed;
        top: 0;
      }
      .nav-list {
        z-index: 11;
        list-style: none;
        top: 0;
        bottom: 0;
        height: auto;
      }
    }
    .nav-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: @h;
      padding: 0.1rem @padding;
      .logo,
      .menu {
        vertical-align: middle;
        height: 0.6rem;
      }
      .menu {
        height: 0.36rem;
        margin-top: 0.12rem;
      }
    }
    .nav-list {
      overflow: auto;
      position: fixed;
      bottom: 100%;
      top: -120%;
      left: 0;
      right: 0;
      font-size: 0.2rem;
      line-height: 0.64rem;
      &-box {
        background: rgba(6, 31, 51, 0.9);
        width: 100%;
        padding: 0.4rem + @h @padding 1px;
        margin: 0;
      }
      hr {
        margin: 0.2rem 0;
      }
      .btn-line {
        text-align: center;
        width: 100%;
        margin: 0.74rem 0;
        .btn {
          @btn_h: 0.72rem;
          background: #f55b23;
          font-size: 0.28rem;
          line-height: 0.28rem;
          padding: 0.2rem 0.8rem;
          font-weight: 300;
          min-width: 34vw;
          border-radius: @btn_h;
          color: #ffffff;
        }
      }
    }
  }
}

@media (min-width: 1120px) {
  .main-header {
    @bg_color: #061f33;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    .header-m {
      display: none;
    }
    .header-pc {
      .index-search {
        .header-searchBtn {
          width: 40px;
          height: 40px;
          cursor: pointer;
          .search-icon {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      @h: 80px;
      @logo_h: 48px;
      @header_padding: @h / 2 - @logo_h / 2;
      display: block;
      background: @bg_color;
      color: rgba(255, 255, 255, 0.7);
      height: @h;
      // padding: @header_padding 0;
      line-height: @logo_h;
      .box-l {
        padding: @header_padding 0;
      }
      .box-r {
        padding-top: @header_padding;
      }
      .box,
      .box-l,
      .box-r {
        height: 100%;
      }
      .box {
        padding: 0 15px;
      }
      &.showSearch {
        .pc-nav-list,
        .login,
        .userNav {
          width: 0;
          height: 0;
          overflow: hidden;
        }
        .box-l {
          flex-shrink: 0;
        }
        .box-r {
          flex: 1;
          padding-left: 40px;
        }
        .index-search {
          width: 100%;
          padding-right: 80px;
          .search-input {
            opacity: 1;
            font-weight: 300;
          }
          .search-btn {
            @btn_h: 26px;
            display: inline-block;
            position: absolute;
            z-index: 112;
            right: 0;
            top: 7px;
            font-size: 18px;
            height: @btn_h;
            line-height: @btn_h;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
      .box {
        display: flex;
        justify-content: space-between;
      }
      .logo {
        height: @logo_h;
        width: 128px;
        vertical-align: top;
      }
      .pc-nav-list,
      .nav-item,
      .index-search,
      .login {
        display: inline-block;
        height: 100%;
      }
      .login {
        margin-left: 10px;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
          color: #f55b23;
        }
      }
      .pc-nav-list {
        margin-left: -24px;
      }
      .nav-item {
        margin-left: 48px;
        font-size: 16px;
        color: #ffffff;
        a {
          &:hover,
          &:active,
          &:focus {
            color: #f55b23;
          }
        }
      }
      .index-search {
        @input_h: 40px;
        position: relative;
        height: @input_h;
        vertical-align: middle;
        &.showSearch {
          position: absolute;
        }
        .search-input {
          opacity: 0.3;
          width: 100%;
          font-size: 18px;
          height: @input_h;
          vertical-align: top;
          width: 100%;
          line-height: @input_h;
          background: #000000;
          border-radius: @input_h;
          padding: 0 22px 0 62px;
          border: 0;
        }
        .search-btn {
          display: none;
        }
        .search-icon,
        .search-close {
          position: absolute;
        }
        .search-icon {
          left: 22px;
          top: 9px;
          width: 22px;
          height: 22px;
        }
        .search-close {
          right: 102px;
          top: 11px;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      .userNav {
        height: 100%;
        padding: 0;
        display: inline-block;
        a {
          text-decoration: none;
        }
        .userNav-icon {
          height: 100%;
          width: 100%;
          .userNav-img {
            @img: 36px;
            width: @img;
            height: @img;
            vertical-align: middle;
            margin: 0 20px;
          }
        }
        .userNav-header {
          background: #031624;
          color: #fff;
        }
        .userNav-body {
          padding: 15px;
          hr {
            border-top: 1px solid #9b9b9b;
          }
        }
        .userNav-list {
          right: 6px;
          top: @h - @header_padding;
          padding: 0;
          background: @bg_color;
          color: #fff;
        }
        .userNav-item {
          // margin-left: -15px;
          // margin-right: -15px;
          // width: auto;
          // padding: 0 15px;
          &:hover,
          &:active,
          &:focus {
            // background: #1d374c;
            background: #061f33;
          }
        }
      }
    }
  }
}
