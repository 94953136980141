.main-body {
  position: relative;
  z-index: 0;
  &-banner {
    position: relative;
    padding-top: 1.3rem;
    background: url(../../../images/home/banner_bg.jpg) no-repeat;
    z-index: -3;
    background-size: cover;
    &::after {
      content: ' ';
      background: url(../../../images/home/indexbanner.png) no-repeat;
      background-position: bottom;
      background-size: 100% auto;
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
    &-con {
      text-align: center;
      .title {
        font-size: 0.36rem;
        color: #ffffff;
        line-height: 0.48rem;
        font-weight: 300;
        .t {
          font-weight: 500;
        }
        span {
          display: block;
        }
      }
      .btns {
        margin-top: 0.4rem;
        .split {
          display: inline;
        }
        .btn-item {
          @btn_h: 0.6rem;
          display: inline-block;
          border-radius: @btn_h;
          width: 33vw;
          padding: 0.18rem 0;
          margin: 0 3vw;
          background: rgba(255, 255, 255, 0.9);
          font-size: 0.24rem;
          line-height: 0.24rem;
          color: #f55b23;
          &:active,
          &:focus,
          &:hover {
            color: #fff;
            background: #f55b23;
          }
        }
      }
    }
    &-cover {
      padding: 0.4rem 18vw 0;
      position: relative;
      z-index: -2;
      .img {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1024px) {
  .main-body {
    position: relative;
    z-index: 0;
    @banner_h: 685px;
    &-banner {
      height: @banner_h;
      font-size: 48px;
      color: #ffffff;
      line-height: 56px;
      padding-top: 0;
      .box {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: flex-end;
      }
      &-con {
        text-align: left;
        width: 480px;
        flex-shrink: 0;
        margin-right: 20px;
        .title {
          font-size: 36px;
          line-height: 46px;
        }
        .btns {
          margin-top: 32px;
          margin-bottom: 72px;
          a {
            line-height: 1;
            font-size: 12px;
            margin-right: 100px;
            margin: 0 0 20px 0;
          }
          .split {
            display: block;
          }
          .btn-item {
            text-align: center;
            @btn_h: 60px;
            border-radius: @btn_h;
            font-weight: 300;
            width: 200px;
            padding: 13px 0;
            margin: 0;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      &-cover {
        padding: 0;
        position: relative;
        z-index: -2;
        margin-left: 20px;
        width: 500px;
        .img {
          width: 100%;
        }
      }
    }
  }
}

body {
  scroll-behavior: smooth;
}
