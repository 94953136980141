//隐藏掉移动端的横向滚动条，但不影响滚动
.indexPage {
  .categories {
    &-title {
      @title_h: 0.9rem;
      margin: 0;
      background: #f3f3f3;
      color: #000000;
      height: @title_h;
      padding: 0 0.12rem;
      .title-wrapper {
        width: 100%;
        height: 100%;
        padding: 0 0.24rem;
        position: relative;
        &.showArrow .arrow {
          display: block;
        }
        .arrow {
          display: none;
          height: 100%;
          width: 0.4rem;
          position: absolute;
          z-index: 2;
          top: 0;
          background: linear-gradient(
            to left,
            rgba(243, 243, 243, 0.6) 10%,
            #f3f3f3 90%
          );
          cursor: pointer;
          span {
            width: 0.16rem;
            height: 0.3rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: url('../../../images/home/arrow.svg') no-repeat;
            background-size: 0.16rem 0.3rem;
          }
          &.right {
            right: 0;
          }
          &.left {
            left: 0;
            transform: rotateY(180deg);
          }
        }
      }
      .box_scrollbar {
        height: 100%;
        overflow: hidden;
        .box {
          overflow-x: auto;
          white-space: nowrap;
          position: relative;
          padding: 0 0.1rem;
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #fff;
          }

          &::-webkit-scrollbar {
            width: 6px;
            background-color: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(245, 91, 35, 0.6);
            border-radius: 4px;
          }
        }
      }
      .tag {
        word-wrap: break-word;
        margin: 0 0.14rem;
        font-weight: 300;
        font-size: 0.24rem;
        line-height: @title_h;
        text-decoration: none;
        transition: color 0.2s;
        cursor: pointer;
        &.active {
          color: #f55b23;
          border-bottom: #f55b23 solid 2px;
        }
      }
    }
    &-list {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.14rem 0;
      .item {
        display: block;
        vertical-align: top;
        margin: 1vw;
        @item_w: 30vw;
        width: @item_w;
        .ctrls {
          display: none;
        }
        &-cover {
          width: 100%;
          height: @item_w;
          overflow: hidden;
          .cover-img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background: #f3f3f3;
          }
        }
        &-title {
          font-size: 0.24rem;
          margin-top: 0.2rem;
          line-height: 0.32rem;
        }
      }
    }
    .btns {
      text-align: center;
      margin: 0.2rem 0;
      .more {
        display: inline-block;
        background: #f55b23;
        font-size: 0.24rem;
        font-weight: 300;
        line-height: 0.24rem;
        color: #ffffff;
        text-align: center;
        width: 2.5rem;
        padding: 0.18rem 0;
        border-radius: 0.64rem;
        border: #ffffff solid 2px;
        &:active,
        &:focus,
        &:hover {
          background: #fff;
          border-color: #f55b23;
          color: #f55b23;
        }
      }
    }
  }
}

@media (min-width: 720px) {
  .indexPage {
    .categories {
      &-title {
        .tag {
          font-size: 20px;
        }
      }
      &-list {
        margin: 2vw -2vw;
        padding: 0;
        .item {
          @item_w: 20vw;
          margin: 2vw;
          width: @item_w;
          &.showCtrls.active .ctrls {
            display: block;
          }
          &.hover {
            .hover-bg {
              position: relative;
              height: 100%;
              .ctrls {
                @w: 5vw;
                position: absolute;
                top: 50%;
                margin-top: -@w / 2;
                width: 100%;
                z-index: 12;
                display: flex;
                justify-content: space-around;
                .img {
                  width: @w;
                  height: @w;
                }
              }
              .ctrls-mask {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.4);
              }
            }
          }
          &-cover {
            height: @item_w;
          }
          &-title {
            font-size: 16px;
            line-height: 22px;
            font-weight: 300;
            margin-top: 10px;
          }
        }
      }
      .btns {
        margin: 30px 0;
        .more {
          font-size: 16px;
          line-height: 24px;
          width: 200px;
          padding: 13px 0;
          border-radius: 50px;
        }
      }
    }
  }
}

@media (min-width: 1140px) {
  .indexPage {
    .categories {
      &-title {
        @title_h: 80px;
        padding: 0;
        height: @title_h;
        .title-wrapper {
          width: 1140px;
          margin: 0 auto;
          position: relative;
          padding: 0;
          .arrow {
            width: 50px;
            span {
              width: 14px;
              height: 26px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-size: cover;
            }
            &.right {
              right: -31px;
            }
            &.left {
              left: -31px;
              transform: rotateY(180deg);
            }
          }
        }
        .box {
          padding: 0 20px;
          position: relative;
          .tag {
            line-height: @title_h;
          }
        }
      }
      &-list {
        margin: 14px -14px;
        .item {
          @item_w: 200px;
          margin: 14px;
          width: @item_w;
          &.hover {
            .hover-bg {
              .ctrls {
                @w: 50px;
                margin-top: -@w / 2;
                .img {
                  width: @w;
                  height: @w;
                }
              }
            }
          }
          &-cover {
            height: @item_w;
          }
        }
      }
      .btns {
        margin: 40px 0;
      }
    }
  }
}
