.userNav {
  &.showNav {
    .userNav-list {
      display: block;
    }
  }
  position: relative;
  cursor: pointer;
  @h: 50px;
  // @fw: 40px;
  // width: @fw;
  height: @h;
  padding: 8px 15px;
  @title_h: 34px;
  &-icon {
    height: @title_h;
  }
  &-img {
    width: @title_h;
    height: @title_h;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: top;
  }
  &-name {
    vertical-align: top;
    display: inline-block;
    height: @title_h;
    line-height: @title_h;
    padding-left: 12px;
    /* &:active, &:hover{
      text-decoration: underline;
    } */
  }
  &-list {
    @w: 280px;
    @itme_h: 34px;
    display: none; // top:  @top_h;
    background-color: white;
    color: black;
    position: absolute;
    right: 0;
    top: @h;
    width: @w;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
      0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
    border-radius: 0 0 4px 4px;
    text-align: left;
    a {
      color: inherit;
      &:active,
      &:hover {
        color: #f55b23;
      }
    }
    .userNav-item {
      cursor: pointer;
      font-size: 14px;
      width: 100%;
      height: @itme_h;
      line-height: @itme_h;
      overflow: hidden;
      line-height: @itme_h;
      list-style: none;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      &:active,
      &:hover {
        color: #f55b23;
        background-color: white;
      }
    }
    .userNav-header {
      display: flex;
      align-items: center;
      padding: 25px 15px;
      background: #f2f2f2;
      &-text {
        margin-left: 10px;
      }
    }

    .userNav-body {
      padding: 15px;
    }
  }

  .icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 26px;
    height: 26px;
    margin-right: 24px;
    &.creator {
      background-image: url('../../images/nav/creator_studio.png');
    }

    &.history {
      background-image: url('../../images/nav/history.png');
    }

    &.favorites {
      background-image: url('../../images/nav/favorite.png');
    }

    &.signOut {
      background-image: url('../../images/nav/sign_out.png');
    }

    &.subscribe {
      background-image: url('../../images/nav/sub.png');
    }

    &.releases {
      background-image: url('../../images/nav/new_release.png');
    }

    &.playlist {
      background-image: url('../../images/nav/playlist.png');
    }
  }
}
