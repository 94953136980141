.loginPage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  &-bottom {
    padding-top: .32rem;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    .loginPage-bottom-link {
      color: #4a90e2;
    }
  }
  &-spilitLine {
    @h: 14px;
    @line_w: 0.6rem;
    @color: rgba(0, 0, 0, 0.32);
    display: inline-block;
    font-size: 12px;
    height: @h;
    line-height: @h;
    color: @color;
    padding: 0 12px;
    margin: 0.2rem auto 0.16rem;
    position: relative;
    &::after,
    &::before {
      border-radius: 0.5px;
      margin-top: -0.5px;
      position: absolute;
      content: ' ';
      top: 50%;
      width: @line_w;
      height: 1px;
      background: @color;
    }
    &::before {
      left: -@line_w;
    }
    &::after {
      right: -@line_w;
    }
  }
  &-img {
    width: 0.56rem;
    height: 0.56rem;
  }
  &-title {
    width: 100%;
    text-align: center;
    height: 22px;
    margin-top: 12px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
  &-con {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.32);
    margin: 0.1rem auto 0.2rem;
    width: 100%;
    padding: 0 15px;
    line-height: 130%;
  }
  .loginBox {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    .loginItem {
      cursor: pointer;
      text-align: center;
      margin: 12px 16px 0;
      float: none;
      display: block;
    }
  }

  .userNav-list {
    position: fixed;
  }
}
