.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.suggestions {
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 0.72rem;
  width: 100%;
  color: #999;
  background-color: white;
}
.suggestions.showSuggestion {
  height: auto;
}
.suggestions .suggestions-title {
  font-size: 18px;
  line-height: 24px;
  padding: 12px 25px;
}
.suggestions .suggestionsResultList {
  list-style: none;
  padding: 0;
  margin: 0;
}
.suggestions .suggestionsResultList-item {
  padding: 12px 25px;
  line-height: 22px;
  font-size: 16px;
  cursor: pointer;
}
.suggestions .suggestionsResultList-item:hover {
  color: #fff;
  background-color: #F55B23;
}
.suggestions .suggestionsResultList-item .itemCon {
  padding-left: 30px;
  background: url(/app/castbox/static/images/searchIcon.png) no-repeat left center;
  background-size: auto 100%;
}
.suggestions .suggestionsResultList-item .itemCon span {
  color: black;
}
@media (min-width: 960px) {
  .suggestions {
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 50px;
    width: 100%;
  }
  .suggestions.showSuggestion {
    height: auto;
  }
  .suggestions .suggestions-title {
    font-size: 18px;
    line-height: 24px;
    padding: 12px 25px;
  }
  .suggestions .suggestionsResultList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .suggestions .suggestionsResultList-item {
    padding: 12px 25px;
    line-height: 22px;
    font-size: 16px;
    cursor: pointer;
  }
  .suggestions .suggestionsResultList-item .itemCon {
    padding-left: 30px;
    background-size: auto 100%;
  }
}
