.loginBtn {
  @f_h: 40px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  width: 223px;
  height: @f_h;
  line-height: @f_h;
  margin: 0 0 24px 0;
  border: 0;
  color: #fff;
  display: inline-block;
  &-img {
    height: 26px;
    margin: 8px 0 6px;
    width: auto;
    line-height: @f_h;
    line-height: auto;
  }
  &-text {
    font-size: 16px;
    margin-left: 14px;
    font-weight: 400;
    line-height: @f_h;
    height: @f_h;
  }
  &.facebook {
    background-color: #3b5998;
  }
  &.google {
    margin: 0;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.54);
  }
  &.twitter {
    background-color: #6ab3ea;
  }
  &.line {
    background-color: #3acf01;
  }
  &.email {
    .loginBtn-img {
      height: 16px;
      margin: 5px 0 6px;
    }
    background-color: #f55b23;
  }
  &.apple {
    .loginBtn-img {
      height: 21px;
      margin: -2px 0 0;
    }
    background-color: #000;
  }
  &.sm {
    width: @f_h;
    margin: 0 12px 14px;
    border-radius: 50%;
    .loginBtn {
      &-text {
        display: none;
      }
    }
  }
}
