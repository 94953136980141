.main-footer {
  background: #061f33;
  color: #fff;
  .footer-pc {
    display: none;
  }
  .footer-m {
    height: auto;
    font-size: 0.24rem;
    ul {
      list-style: none;
      padding: 0;
    }
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0.32rem 0.3rem 0.32rem;
      .down-link {
        width: 32vw;
        margin-bottom: 0.54rem;
      }
      .footer-m-line {
        vertical-align: middle;
        @line_h: 0.58rem;
        &.t {
          line-height: @line_h;
          margin-top: 0.1rem;
          font-size: 0.26rem;
          font-weight: 300;
        }
        .icon {
          margin-right: 0.1rem;
          width: 0.24rem;
          height: 0.24rem;
        }
        a {
          font-weight: 300;
          line-height: @line_h;
          font-size: 0.24rem;
          opacity: 0.8;
        }
      }
      .l,
      .r {
        margin: 0 0.3rem;
      }
    }
  }
}

@media (min-width: 960px) {
  .main-footer {
    .footer-m {
      display: none;
    }
    .footer-pc {
      display: block;
      padding: 48px 15px;
      font-weight: 300;
      a {
        &:hover,
        &:active,
        &:focus {
          color: #f55b23;
        }
      }
      .footer-links {
        margin-top: 40px;
        font-size: 13px;
        line-height: 19px;
        .split {
          margin: 0 4px;
        }
      }
      .box {
        .content,
        .box-r {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .box-l {
          .logo {
            height: 54px;
            margin-bottom: 24px;
          }
          .text {
            font-size: 13px;
            font-weight: 300;
            line-height: 25px;
          }
          .btns {
            margin-top: 18px;
            .btn-item {
              display: inline-block;
              height: 32px;
              width: 106px;
              margin-right: 10px;
              margin-bottom: 10px;
              &:last-child {
                margin-right: 0;
                width: 100px;
              }
              .img {
                height: 100%;
              }
            }
          }
        }
        .box-r {
          margin-left: -30px;
          padding-top: 20px;
          justify-content: flex-start;
          .links {
            margin-left: 60px;
            min-width: 120px;
            .links-t {
              font-weight: 300;
              margin-bottom: 10px;
            }
            .link {
              display: block;
              opacity: 0.8;
              font-size: 14px;
              line-height: 25px;
              margin: 12px 0;
              &:hover,
              &:active,
              &:focus {
                color: #f55b23;
              }
            }
            .socials {
              @h: 32px;
              height: @h;
              min-width: 218px;
              margin: 16px -20px 30px 0;
              .icon {
                height: @h;
                padding: 1px;
                margin-right: 20px;
                &.tel {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
